// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

type Items = {
	data: Array<ItemType>,
	isBusy: boolean,
};

const initialState: Items = {
	data: [],
	isBusy: false,
};

type Action =
	| { type: typeof actions.GET_ITEMS.START, payload: { collection: string } }
	| { type: typeof actions.GET_ITEMS.SUCCESS, payload: { items: Array<ItemType> } }
	| { type: typeof actions.GET_ITEMS.FAIL, payload: { collection: string } };

export default function itemsReducer(state: Items = initialState, action: Action) {
	return produce<any>(state, (draft: Items) => {
		switch (action.type) {
			case actions.GET_ITEMS.START:
				draft.isBusy = true;
				break;

			case actions.GET_ITEMS.SUCCESS:
				draft.data = action.payload.items;
				draft.isBusy = false;
				break;

			case actions.GET_ITEMS.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
