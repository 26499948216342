// @flow

export const GET_ITEMS = {
	START: 'GET_ITEMS.START',
	SUCCESS: 'GET_ITEMS.SUCCESS',
	FAIL: 'GET_ITEMS.FAIL',
};

export const GET_CATEGORIES = {
	START: 'GET_CATEGORIES.START',
	SUCCESS: 'GET_CATEGORIES.SUCCESS',
	FAIL: 'GET_CATEGORIES.FAIL',
};

export const UPDATE_VOTES = {
	START: 'UPDATE_VOTES.START',
	SUCCESS: 'UPDATE_VOTES.SUCCESS',
	FAIL: 'UPDATE_VOTES.FAIL',
};

export const INIT_SWIPER = 'INIT_SWIPER';

export const ADD_VOTE = 'ADD_VOTE';
export const REMOVE_VOTE = 'REMOVE_VOTE';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const SET_POP_UP = 'SET_POP_UP';
