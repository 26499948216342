// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';

import popUp from './pop-up';
import vote from './vote';
import swiper from './swiper';
import items from './items';
import categories from './categories';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	items,
	categories,
	popUp,
	vote,
	swiper,
});

export default reducer;
