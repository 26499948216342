/* eslint-disable react/no-unescaped-entities */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import firebase from 'firebase/app';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Responsive } from 'client/components/common/Responsive';
import { fileLink } from 'common/utils';
import { dataLayerEvents } from 'common/utils/gtag';
// import { analytics } from 'common/utils/ga';
import { APP_CONFIG, IS_DEVEL } from 'common/constants';
import { THANKS_ROUTE, VOTE_ROUTE } from 'client/constants';
import { Header, Footer, Icon } from 'client/components/common';
import Popup from 'client/components/common/Popup';

import bg from 'assets/client/2024/bg-vote-3.jpg';
import { updateVotes } from '../../../actions';

import * as css from './Results.scss';

// const GA_VOTE_ACTION = {
// 	SONGS: 'Song',
// 	ARTISTS: 'Artist',
// 	BREAKTHROUGH: 'Breakthrough',
// 	ALBUM: 'Album',
// 	SHOW: 'Show',
// };

type Props = {|
	...EnhancedProps,
	in: boolean,
	vote: Array<Array<string>>,
	allItems: Array<ItemType>,
	categories: CategoryType[],
	history: RouterHistory,
	submitVotes: typeof updateVotes,
|};

type State = {
	recaptchaSolved: boolean,
	isScrolling: boolean,
	isPopupOpen: boolean,
};

class Results extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	state = {
		recaptchaSolved: false,
		isScrolling: true,
		isPopupOpen: false,
	};

	componentDidMount = async () => {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));

		if (!IS_DEVEL) {
			const hostingUrl = APP_CONFIG.domains.client.hostname;
			const fullUrl = `${hostingUrl}/YamTichonParade2024/Results`;
			dataLayerEvents.pageView(fullUrl, 'מצעד אגן הים התיכון - תוצאות');
		}

		if (!Responsive.isMatching(Responsive.MOBILE)) {
			window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(`recaptcha-container`, {
				size: 'normal',
				callback: response => {
					this.setState({ recaptchaSolved: true });
					console.info('reCAPTCHA solved');
				},
			});

			window.recaptchaVerifier.render();
		}
		// if (Responsive.isMatching(Responsive.MOBILE)) {
		// 	document.addEventListener('scroll', this.onScroll);
		// }
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	sendVotesToServer = () => {
		const { vote, allItems, /* categories, */ history, submitVotes } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		const votesList: string[] = [];
		vote.forEach(tab => tab.forEach(voteId => votesList.push(voteId)));

		if (isMobile) {
			submitVotes({ data: votesList, collection: 'items' });
			this.setState({ isPopupOpen: false });
		}

		if (!isMobile && this.state.recaptchaSolved) {
			submitVotes({ data: votesList, collection: 'items' });
		}

		if (!IS_DEVEL) {
			dataLayerEvents.siteNavigationClick('Finish Voting');

			vote.forEach(voteItemArray => {
				voteItemArray.forEach(voteItemArrayItem => {
					const itemInfo = allItems.find(item => item.id === voteItemArrayItem);
					// for one category
					if (itemInfo) {
						if (itemInfo.song) {
							dataLayerEvents.onVoted('Song', `${itemInfo?.song} - ${itemInfo?.artist}`);
						}
					}
				});
			});
		}

		localStorage.setItem('102_voted_v3', 'true');
		history.push(THANKS_ROUTE);
	};

	onClickSendButton = e => {
		e.preventDefault();
		const { vote, categories } = this.props;

		// validate votes, go to the tab that has not complete minVotes
		vote.filter((item, index, arr) => index < categories.length).every((votes, index, arr) => {
			if (votes.length < Number(categories[index].minItems)) {
				return false;
			}
			if (index === arr.length - 1) {
				this.sendVotesToServer();
			}

			return true;
		});
	};

	renderCategory = c => {
		const { vote, allItems } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		return (
			<div className={css.category} key={c.id}>
				<h6>{c.name}</h6>
				<div className={css.list}>
					{_.map(vote[c.orderData], id => {
						const item = _.find(allItems, { id });
						return (
							item && (
								<div className={css.item} key={id}>
									<div className={css.imageWrap}>
										<img
											src={isMobile ? fileLink(item.imageMobile) : fileLink(item.image)}
											alt={item.artist}
										/>
									</div>
									<p>
										<b>{item.artist}</b>
										{item.song && <span>{item.song}</span>}
									</p>
								</div>
							)
						);
					})}
				</div>
			</div>
		);
	};

	render() {
		const { transitionState, categories } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.results, css[transitionState])} id="results">
				<Header />
				<div style={{ backgroundImage: `url(${bg})` }} className={css.bg} />
				<div className={css.content}>
					<Link to={VOTE_ROUTE} className={css.buttonBack}>
						<Icon type="back" />
						<span>חזרה לקטגוריות</span>
					</Link>
					<div className={css.categories}>
						{_.map(categories, (c, index) => {
							if (index <= 1) {
								return (
									<div className={css.categoryCol} key={c.id}>
										{this.renderCategory(c)}
									</div>
								);
							}
						})}
						<div className={css.categoryCol}>
							{_.map(categories, (c, index) => {
								return index > 1 && this.renderCategory(c);
							})}
						</div>
					</div>
					{!isMobile && (
						<>
							<div id="recaptcha-container" className={classNames(css.captcha)} />
							<button
								className={classNames(css.submitButton, !this.state.recaptchaSolved && css.disable)}
								id="submit-button"
								type="button"
								onClick={this.onClickSendButton}
							>
								סיימתי להצביע
							</button>
						</>
					)}
				</div>
				<Footer withCredentials />
				{isMobile && (
					<>
						<div className={classNames(css.floatingButtonWrap, this.state.isScrolling && css.show)}>
							<button
								className={css.floatingButton}
								type="button"
								onClick={() => this.setState({ isPopupOpen: true })}
							>
								סיימתי להצביע
							</button>
						</div>
						{this.state.isPopupOpen && (
							<Popup
								onClickSendButton={this.onClickSendButton}
								onClose={() => this.setState({ isPopupOpen: false })}
							/>
						)}
					</>
				)}
			</div>
		);
	}
}

export default withTransition(Results);
