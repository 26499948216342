// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import { addVote, removeVote, updateVotes } from 'client/actions';
import Vote from './Vote';

const mapState = (state: ClientStore) => {
	const iDs = _.map(_.get(state.categories, 'data', []), i => i.id);
	const itemsArr = _.reduce(
		_.get(state.items, 'data', []),
		(acc, i, index: number) => {
			if (iDs.includes(i.categoryId)) {
				if (acc[i.categoryId]) {
					acc[i.categoryId].push(i);
				} else {
					acc[i.categoryId] = [i];
				}
			}

			return acc;
		},
		{}
	);

	return {
		items: itemsArr,
		categories: _.orderBy(_.get(state.categories, 'data', []), 'orderData'),
		vote: state.vote,
		allItems: _.get(state.items, 'data', []),
	};
};

const mapDispatch = {
	addVote,
	removeVote,
	updateVotes,
};

export default connect(mapState, mapDispatch)(Vote);
