/* eslint-disable max-len */
// @flow
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	FACEBOOK: 'Facebook',
	EMAIL: 'Email',
};

export const COLLECTIONS = {
	COUNTRIES: 'countries',
	SETTINGS: 'settings',
};

export const IMAGE_POP_UP = 'IMAGE_POP_UP';
