// @flow
import * as actions from 'client/constants/actions';
import { updateVotes as updateUsersVotes } from 'api/crud';
import { apiRequestAction } from './helpers/api-request';

export function updateVotes(params: {| collection: string, data: Array<string> |}): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.UPDATE_VOTES,
			request: updateUsersVotes,
			params,
		});

		return dispatch(requestAction);
	};
}

export function addVote(page: number, id: string) {
	return (dispatch: Dispatch) => dispatch({ type: actions.ADD_VOTE, payload: { page, id } });
}
export function removeVote(page: number, id: string) {
	return (dispatch: Dispatch) => dispatch({ type: actions.REMOVE_VOTE, payload: { page, id } });
}
