/* eslint-disable react/no-unescaped-entities */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
// import { events } from 'common/utils/ga';
import bgImg from 'assets/client/2024/bg-intro-1.jpg';
import ynetLogo from 'assets/client/ynet_logo.svg';
import bgMobile from 'assets/client/2024/intro-mobile.svg';
// import logoInText from 'assets/client/logo-in-text.png';
import mainText from 'assets/client/2024/img-with-text.svg';
import mainLogo from 'assets/client/2024/site-logo.svg';

import { Link } from 'react-router-dom';
import { VOTE_ROUTE, THANKS_ROUTE } from 'client/constants';
import { Header, Footer } from 'client/components/common';
import { APP_CONFIG, IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
// import { analytics } from 'common/utils/ga';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	in: boolean,
	history: RouterHistory,
|};

type State = {};

class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	componentDidMount() {
		const { onTransitionComplete, history } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));

		if (localStorage.getItem('102_voted_v3') === 'true') {
			history.push(THANKS_ROUTE);
		}

		if (!IS_DEVEL) {
			const hostingUrl = APP_CONFIG.domains.client.hostname;
			// const domain = window.location.origin;
			const fullUrl = `${hostingUrl}/YamTichonParade2024`;
			dataLayerEvents.pageView(fullUrl, 'מצעד אגן הים התיכון - התחלה');
			// analytics.gtag.event('page_view', {
			// 	page_title: 'המצעד הישראלי השנתי של גלגלצ 2023 - התחלה',
			// 	page_location: `${fullUrl}`,
			// 	page_path: '/Galgalatz_parade_2023',
			// });
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	onClickToVote = () => {
		if (!IS_DEVEL) {
			dataLayerEvents.siteNavigationClick('Start Voting');
		}
	};

	render() {
		const { transitionState } = this.props;
		const ynetLink = 'https://www.ynet.co.il';

		return (
			<div className={classNames(css.intro, css[transitionState])} style={{ backgroundImage: `url(${bgImg})` }}>
				<Header isHideCenterText isMain />
				<div className={css.content}>
					<div className={css.contentRight}>
						<h1>
							<img src={mainText} alt="" />
						</h1>
						{/* <div className={css.logos}>
							<p>של</p>
							<a href={ynetLink} className={css.ynetLogo} target="_blank" rel="noreferrer">
								<img src={ynetLogo} alt="ynet logo" />
							</a>
							<a href={glglzLink} className={css.glglzLogo} target="_blank" rel="noreferrer">
								<img src={glglzLogo} alt="glglz logo" />
							</a>
						</div> */}
						<h2>
							<a href={ynetLink} className={css.ynetLogo} target="_blank" rel="noreferrer">
								<img src={ynetLogo} alt="ynet logo" />
							</a>
							מארח את מצעד <br /> אגן הים התיכון של
							<div className={css.siteLogo}>
								<img src={mainLogo} alt="site logo" />
							</div>
							<p>ואתם מוזמנים לבחור את השיר הים תיכוני הגדול של כל הזמנים</p>
						</h2>
						<div className={css.mobileImg}>
							<img src={bgMobile} alt="background mobile" />
						</div>
						<div className={css.time}>
							אז קדימה - הצביעו והשפיעו!
							<br />
							התוצאות הסופיות ייחשפו במשדר מיוחד ביום העצמאות, 14 במאי, בכאן גימל ובאתר ynet
						</div>
						<Link to={VOTE_ROUTE} onClick={this.onClickToVote} className={css.button}>
							התחילו להצביע
						</Link>
					</div>
				</div>
				<Footer withCredentials />
			</div>
		);
	}
}

export default withTransition(Intro);
