// @flow
import _ from 'lodash';
import { APP_CONFIG } from 'common/constants';

const API_URL = APP_CONFIG.domains.backend.url;

class ApiWorker {
	_token: string;

	constructor() {
		this.token = '';
	}

	get token() {
		return this._token;
	}

	set token(val: string) {
		this._token = val;
	}

	getResultFrame = <R>(): BackEndResponse<R> => {
		return { success: false, error: '' };
	};

	getResFrameFromError = <R>(e: Error): BackEndResponse<R> => {
		const result = this.getResultFrame<R>();

		result.error = JSON.parse(e.message || 'something went wrong');
		return result;
	};

	send = async <R>({ url, method, body = null, secure = false }: ApiWorkerParams): Promise<BackEndResponse<R>> => {
		const targetUrl = `${API_URL}${url}`;
		const headers = { 'Content-Type': 'application/json; charset=utf-8' };
		const options = { mode: 'cors' };
		let result = this.getResultFrame<R>();

		if (secure) {
			_.set(headers, 'Authorization', `Bearer ${this.token}`);
		}

		_.set(options, 'method', method);
		_.set(options, 'headers', headers);

		if (method !== 'GET') {
			_.set(options, 'body', JSON.stringify(body));
		}

		try {
			const response = await fetch(targetUrl, options);
			const jsonResp = await response.json();

			if (response.status === 200 || response.status === 204) {
				result.success = true;
				result.data = jsonResp.data;
			} else {
				result.error = jsonResp.error || jsonResp.message || 'Something went wrong';
			}
		} catch (e) {
			result = this.getResFrameFromError<R>(e);
		}

		return result;
	};

	download = async <R>({
		url,
		method,
		body = null,
		secure = false,
	}: ApiWorkerParams): Promise<BackEndResponse<R>> => {
		const targetUrl = `${API_URL}${url}`;
		const headers = { 'Content-Type': 'application/json; charset=utf-8' };
		const options = { mode: 'cors' };

		if (secure) {
			_.set(headers, 'Authorization', `Bearer ${this.token}`);
		}

		_.set(options, 'method', method);
		_.set(options, 'headers', headers);

		if (method !== 'GET') {
			_.set(options, 'body', JSON.stringify(body));
		}

		try {
			const response = await fetch(targetUrl, options);
			const blob = await response.blob();
			const blobUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = blobUrl;
			a.download = 'אקסל לידים.csv';
			document.body.appendChild(a);
			a.click();
			a.remove();
		} catch (e) {
			throw new Error('test');
		}

		return { success: true };
	};
}

export default new ApiWorker();
