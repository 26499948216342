// @flow
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// import firebase from 'firebase/app';
import 'firebase/auth';

import { Responsive } from 'client/components/common/Responsive';
import VoteCard from 'client/components/common/VoteCard';
import { fileLink } from 'common/utils';
import { APP_CONFIG, IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
// import { analytics } from 'common/utils/ga';

import Swiper from 'swiper';
import * as css from './VoteSection.scss';

type Props = {
	items?: ItemType[],
	activeCategoryId: string,
	pageIndex: number,
	categoryId: string,
	category: CategoryType,
	categoriesLength: number,
	history: RouterHistory,
	vote: string[][],
	categories: CategoryType[],
	allItems: ItemType[],
	swiper: null | Swiper,
	getActiveCategory: Function,
};

let audio = null;

const VoteSection = ({
	category,
	items = [],
	pageIndex,
	activeCategoryId,
	categoryId,
	categoriesLength,
	history,
	vote,
	categories,
	allItems,
	swiper,
	getActiveCategory,
}: Props) => {
	const [currentItemId, setCurrentId] = useState(null);
	const [audioDuration: number, setAudioDuration] = useState(0);
	const [audioCurrentTime: number, setAudioCurrentTime] = useState(0);
	// const [recaptchaSolved: boolean, setRecaptcha] = useState(false);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const [shuffledItems, setShuffledItems] = useState<ItemType[]>(items);

	useEffect(() => {
		setShuffledItems(
			_.shuffle(
				[...shuffledItems]
					.map(value => ({ value, sort: Math.random() }))
					.sort((a, b) => a.sort - b.sort)
					.map(({ value }) => value)
			)
		);
	}, []);

	useEffect(() => {
		const lastPageIndex = 4;
		if (pageIndex === lastPageIndex) {
			// window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(`recaptcha-container-${lastPageIndex}`, {
			// 	size: 'normal',
			// 	callback: response => {
			// 		setRecaptcha(true);
			// 		console.info('reCAPTCHA solved');
			// 	},
			// });
			// window.recaptchaVerifier.render();
			// history.push(RESULTS_ROUTE);
		}
	}, [pageIndex]);

	useEffect(() => {
		if (!IS_DEVEL) {
			if (categoryId === activeCategoryId) {
				const { virtualPageName, virtualPageTitle } = category;
				const hostingUrl = APP_CONFIG.domains.client.hostname;
				const virtualPageUrl = `${hostingUrl}/YamTichonParade2024/${virtualPageName}`;
				dataLayerEvents.pageView(virtualPageUrl, virtualPageTitle);
				// analytics.gtag.event('page_view', {
				// 	page_title: `${virtualPageTitle}`,
				// 	page_location: `${virtualPageUrl}`,
				// 	page_path: `/Galgalatz_parade_2023/${virtualPageName}`,
				// });
			}
		}

		return () => {
			if (audio) {
				setCurrentId(null);
				audio.pause();
				audio.removeEventListener('ended', onAudioEndedListener);
				audio.removeEventListener('timeupdate', onAudioTimeUpdateEvent);
				audio.removeEventListener('loadedmetadata', onAudioLoadedListener);
			}
		};
	}, [activeCategoryId]);

	const onPlayClick = (id, fileURL) => {
		if (audio) {
			audio.pause();
			audio.removeEventListener('ended', onAudioEndedListener);
		}

		if (currentItemId === id) {
			setCurrentId(null);
		} else {
			audio = new Audio(fileURL);
			audio.addEventListener('loadedmetadata', onAudioLoadedListener);
			audio.addEventListener('timeupdate', onAudioTimeUpdateEvent);
			audio.play().catch(_.noop);
			audio.addEventListener('ended', onAudioEndedListener);
			setCurrentId(id);
		}
	};

	const onAudioEndedListener = () => {
		setCurrentId(null);
	};

	const onAudioLoadedListener = () => {
		if (audio) {
			setAudioDuration(audio?.duration);
		}
	};

	const onAudioTimeUpdateEvent = () => {
		if (audio) {
			setAudioCurrentTime(audio.currentTime);
		}
	};

	// const sendVotesToServer = () => {
	// 	const votesList: string[] = [];
	// 	vote.forEach(tab => tab.forEach(voteId => votesList.push(voteId)));

	// 	if (recaptchaSolved) {
	// 		submitVotes({ data: votesList, collection: 'items' });
	// 	}

	// 	if (!IS_DEVEL) {
	// 		dataLayerEvents.siteNavigationClick('Finish Voting');

	// 		vote.forEach(voteItemArray => {
	// 			voteItemArray.forEach(voteItemArrayItem => {
	// 				const itemInfo = allItems.find(item => item.id === voteItemArrayItem);
	// 				const curCategoryId = itemInfo && itemInfo.categoryId;
	// 				const categoryInfo = categories.find(item => item.id === curCategoryId);
	// 				const votingStage = categoryInfo && categoryInfo.virtualPageName;
	// 				let selection;
	// 				if (itemInfo) {
	// 					if (itemInfo.song) {
	// 						selection = itemInfo.song;
	// 					} else {
	// 						selection = itemInfo.artist;
	// 					}
	// 				}

	// 				dataLayerEvents.onVoted(votingStage, selection);
	// 			});
	// 		});
	// 	}

	// 	localStorage.setItem('102_voted_v2', 'true');
	// 	history.push(THANKS_ROUTE);
	// };

	// const onClickSendButton = e => {
	// 	e.preventDefault();

	// 	// validate votes, go to the tab that has not complete minVotes
	// 	vote.filter((item, index, arr) => index < categories.length).every((votes, index, arr) => {
	// 		if (votes.length < Number(categories[index].minItems)) {
	// 			if (swiper) {
	// 				swiper.slideTo(index);
	// 				getActiveCategory(categories[index].id);
	// 			}
	// 			return false;
	// 		}
	// 		if (index === arr.length - 1) {
	// 			sendVotesToServer();
	// 		}

	// 		return true;
	// 	});
	// };

	return (
		<div className={css.voteSection}>
			<div className={`${css.container}`}>
				{shuffledItems.map((item, index) => (
					<VoteCard
						key={item.id}
						id={item.id}
						page={pageIndex}
						image={isMobile ? fileLink(item.imageMobile) || fileLink(item.image) : fileLink(item.image)}
						name={item.song ? item.song : item.artist}
						artist={item.song ? item.artist : ''}
						mp3={fileLink(item.mp3)}
						onPlayClick={onPlayClick}
						isPlaying={item.id === currentItemId}
						categories={categories}
						audioDuration={audioDuration}
						audioCurrentTime={audioCurrentTime}
					/>
				))}

				{/* {categoriesLength === pageIndex + 1 && (
					<div
						id={`recaptcha-container-${pageIndex}`}
						className={classNames(css.captcha, categoriesLength !== pageIndex + 1 && css.hidden)}
					/>
				)} */}
				{/* {categoriesLength === pageIndex + 1 && (
					<div className={classNames(css.buttonWrapper, !recaptchaSolved && css.disable)} id="submit-button">
						<a href="#" onClick={onClickSendButton}>
							סיימתי להצביע
						</a>
					</div>
				)} */}
			</div>
		</div>
	);
};

VoteSection.defaultProps = {
	items: [],
};

export default VoteSection;
