// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Responsive } from 'client/components/common/Responsive';
// import { events } from 'common/utils/ga';

import bg from 'assets/client/2024/bg-vote-3.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { Header, Footer } from 'client/components/common';
import ProgressBar from 'client/components/common/ProgressBar';
import VoteSection from '../../common/VoteSection';

import { addVote, /* initSwiper, */ removeVote } from '../../../actions';

import * as css from './Vote.scss';

SwiperCore.use([EffectFade]);

type Props = {|
	...EnhancedProps,
	in: boolean,
	// initSwiper: typeof initSwiper,
	items: {| [key: string]: ItemType[] |},
	addVote: typeof addVote,
	removeVote: typeof removeVote,
	categories: CategoryType[],
	history: RouterHistory,
	vote: Array<Array<string>>,
	allItems: Array<ItemType>,
	scrollTopContent: Function,
	// scrollToButton: Function,
|};

type State = {
	swiper: null | Swiper,
	activeCategoryId: string,
};

class Vote extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	state: State = {
		swiper: null,
		activeCategoryId: '',
	};

	componentDidMount() {
		const { onTransitionComplete, categories, scrollTopContent } = this.props;

		const activeCategory = categories.find(item => item.orderData === 0);
		const activeId = activeCategory && activeCategory.id;

		this.setState({
			activeCategoryId: activeId,
		});

		scrollTopContent();
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	getActiveCategory = categoryId => {
		this.setState({ activeCategoryId: categoryId });
	};

	render() {
		const {
			transitionState,
			items,
			categories,
			history,
			vote,
			allItems,
			scrollTopContent,
			// scrollToButton,
		} = this.props;
		const { activeCategoryId } = this.state;
		const categoriesLength = categories.length;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<React.Fragment>
				<div className={classNames(css.vote, css[transitionState])}>
					<div className={css.topWrapper} style={isMobile ? null : { backgroundImage: `url(${bg})` }}>
						<Header
							className={css.header}
							progressBar={() =>
								this.state.activeCategoryId && (
									<ProgressBar
										swiper={this.state.swiper}
										activeCategoryId={this.state.activeCategoryId}
										getActiveCategory={this.getActiveCategory}
										// scrollToButton={scrollToButton}
									/>
								)
							}
							isShowProgress
						/>
						<div className={css.voteContent}>
							<Swiper
								onInit={swiper => {
									this.setState({ swiper });
								}}
								effect={isMobile ? 'none' : 'fade'}
								allowTouchMove={false}
								fadeEffect={{ crossFade: true }}
								autoHeight
								onSlideChange={() => scrollTopContent()}
							>
								{categories.map((category, index) => (
									<SwiperSlide key={category.id}>
										{category.description && (
											<Responsive query={[Responsive.DESKTOP, Responsive.TABLET_L]}>
												<p className={`${css.description} `}>{category.description}</p>
											</Responsive>
										)}
										<VoteSection
											items={items[category.id]}
											pageIndex={index}
											addVote={this.props.addVote}
											removeVote={this.props.removeVote}
											category={category}
											activeCategoryId={activeCategoryId}
											categoryId={category.id}
											categoriesLength={categoriesLength}
											history={history}
											vote={vote}
											categories={categories}
											allItems={allItems}
											// submitVotes={this.props.updateVotes}
											swiper={this.state.swiper}
											getActiveCategory={this.getActiveCategory}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						<p className={css.credits}>
							צילומים: כאן ארכיון, איתי דודי, רועי חסן, טל עבודי, לידור חדידה, יניר סלע, שי פרנקו, גילי
							אלגבי, רונן אקרמן, ניוקולן, יעקב גרוס, אלון, דולב, סטודיו דן לב, ויקיפדיה, רן יחזקאל, אמיר
							גלעד, שי כהן ארבל, גבריאל בהרליה, גיא זלצר, דודי חסון, ארן חן צלמים, זוהר רון, יעל פלטקין,
							אריאל לינסון, אילן בשור, דורון עדות, משה נחומוביץ, לע&quot;מ, גיל מירן, גיל חתוקה, מיקה
							רבזין, דובר צה&quot;ל, רמי זרנגר, אדיר פדוני נדוף, ראובן קסטרו, אפי דן, תם מרשק, תומר טרלן
							בן אבי, שני אפרת, דני מילר, אורי אליהו, ורדה אמון, רמי זרגרי, עמרי רוזנגרט, דודי לוי
						</p>
						<Footer withCredentials isRelative />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withTransition(Vote);
