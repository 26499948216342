/* eslint-disable no-confusing-arrow */
import _ from 'lodash';
import { APP_CONFIG } from 'common/constants';

const PROJECT_ID = 'fm-ynet';

export const fileLink = data => {
	if (!data) {
		return null;
	}

	return data.selected === 'file' ? formatLink(_.get(data, 'file.url', '')) : _.get(data, 'remoteURL', '');
};

const formatLink = path => {
	const CDN = `${APP_CONFIG.cdn.hostname}`;
	const REGULAR_URL = `https://storage.googleapis.com/${PROJECT_ID}.appspot.com/`;

	if (!CDN) {
		return `${REGULAR_URL}${path}`;
	}

	return `${CDN}/${path}`;
};
