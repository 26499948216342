// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import playIcon from 'assets/client/play-icon.svg';
import pauseIcon from 'assets/client/pause-icon.svg';
import checkIcon from 'assets/client/check-icon.svg';
import { addVote as addVoteAction, removeVote as removeVoteAction } from 'client/actions';
import { IS_DEVEL } from 'common/constants';
import { RESULTS_ROUTE } from 'client/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import { Responsive } from 'client/components/common/Responsive';
import * as css from './VoteCard.scss';

type Props = {
	name?: string,
	artist?: string,
	image?: string,
	mp3?: string,
	page: number,
	id: string,
	addVote: typeof addVoteAction,
	removeVote: typeof removeVoteAction,
	onPlayClick: Function,
	isPlaying: boolean,
	audioDuration: number,
	audioCurrentTime: number,
	vote: string[][],
	categories: CategoryType[],
};

function VoteCard({
	name,
	artist,
	mp3,
	image,
	page,
	addVote,
	removeVote,
	onPlayClick,
	id,
	isPlaying,
	audioCurrentTime,
	audioDuration,
	vote,
	categories,
}: Props) {
	const [voted, setVoted] = useState(vote[page].includes(id) || false);
	const [playing, setPlay] = useState('');
	const [hover, setHover] = useState(false);
	// const [progress, setProgress] = useState(-314);
	const history = useHistory();
	const categoriesSize = _.size(categories);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);

	// useEffect(() => {
	// if (audioCurrentTime > 0) {
	// const circumference = 50 * 2 * Math.PI;
	// 314px
	// const one = audioDuration / 100;
	// const persents = audioCurrentTime / one;
	// const length = (circumference / 100) * persents;
	// const progressLine = -(314 + length);
	// setProgress(progressLine);
	// }
	// }, [audioCurrentTime]);

	const onPlayButtonClick = e => {
		e.stopPropagation();
		const playingID = playing === id ? null : id;

		onPlayClick(id, mp3);
		if (!IS_DEVEL) {
			if (playingID && !isPlaying) {
				dataLayerEvents.onPlaySoundClick(artist, name);
			}
		}
		// setProgress(-314);
		setPlay({ playing: playingID });
	};

	const onItemClick = () => {
		if (!voted) {
			if (Number(categories[page].maxItems) === vote[page].length) {
				// if votes reach maxItems, do nothing
			} else {
				addVote(page, id);
				setVoted(true);
				if (categoriesSize === page + 1 && Number(categories[page].minItems) <= vote[page].length + 1) {
					window.scrollTo(0, 0);
					history.push(RESULTS_ROUTE);
				}
			}
		} else {
			removeVote(page, id);
			setVoted(false);
		}
	};

	return (
		<>
			<div className={`${css.voteCard} `}>
				<div
					onClick={() => {
						if (!isMobile) {
							onItemClick();
						} else {
							setHover(!hover);
						}
					}}
					onMouseEnter={() => !isMobile && setHover(true)}
					onMouseLeave={() => !isMobile && setHover(false)}
					className={`${css.card}  ${voted ? css.voted : ''}`}
				>
					<div
						className={`${css.overlay} ${hover || voted || isPlaying ? css.hidden : ''}`}
						style={{
							/* eslint-disable no-nested-ternary */
							// stylelint-disable
							background: isMobile
								? voted
									? 'rgba(#ff911e, 0.6)'
									: 'rgba(0,0,0,0)'
								: 'rgba(#ff911e, 0.6)',
						}}
					>
						<div className={`${css.inner}`}>
							<button
								type="button"
								className={`${css.vote} ${!voted ? css.voted : ''} ${
									_.isEmpty(mp3) ? css.centered : ''
								}`}
								onClick={() => {
									if (isMobile) {
										onItemClick();
									}
								}}
							>
								<img className={`${!voted ? css.hidden : ''}`} src={checkIcon} alt="" />
								{/* {isMobile ? (
									<img className={`${voted ? css.hidden : ''}`} src={addIcon} alt="" />
								) : ( */}
								{!voted && <p className={`${voted ? css.hidden : ''}`}>בחירה</p>}
								{/* )} */}
							</button>
							{mp3 && !voted && (
								<button
									type="button"
									onClick={onPlayButtonClick}
									className={`${css.play} ${isPlaying ? css.played : ''}`}
								>
									{/* <svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 112 112"
										className={css.audioProgress}
									>
										<circle
											cx="56"
											cy="56"
											r="50"
											fill="none"
											strokeWidth="12"
											stroke={isPlaying && audioCurrentTime > 0 ? '#e50019' : 'transparent'}
											style={{ strokeDashoffset: `${progress}px` }}
										/>
									</svg> */}
									<div className={css.playButtonInner}>
										<img className={`${isPlaying ? css.hidden : ''}`} src={playIcon} alt="" />
										<img className={`${!isPlaying ? css.hidden : ''}`} src={pauseIcon} alt="" />
									</div>
								</button>
							)}
						</div>
					</div>

					{/* {image && <LazyLoadImage src={image} alt={artist || name} />} */}
					{image && <LazyLoadImage src={image} alt={artist || name} />}
				</div>
				<p className={`${css.name}`}>{name}</p>
				<p className={`${css.artist}`}>{artist}</p>
			</div>
		</>
	);
}

VoteCard.defaultProps = {
	mp3: null,
	name: '',
	artist: '',
	image: '',
};

const mapState = (state: ClientStore) => {
	return {
		vote: state.vote,
	};
};

const mapDispatch = {
	addVote: addVoteAction,
	removeVote: removeVoteAction,
};

export default connect(mapState, mapDispatch)(VoteCard);
