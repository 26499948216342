// @flow

import Swiper from 'swiper';

import * as actions from 'client/constants/actions';

export type Action = {
	type: string,
	payload: any,
};

export type SwiperState = {
	swiper: null | Swiper,
};

const initialState: SwiperState = {
	swiper: null,
};

export default function itemReducer(state: SwiperState = initialState, { type, payload }: Action) {
	switch (type) {
		case actions.INIT_SWIPER:
			return {
				swiper: payload.swiper,
			};
		default:
			return initialState;
	}
}
