// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

type Categories = {
	data: Array<CategoryType>,
	isBusy: boolean,
};

const initialState: Categories = {
	data: [],
	isBusy: false,
};

type Action =
	| { type: typeof actions.GET_CATEGORIES.START, payload: { collection: string } }
	| { type: typeof actions.GET_CATEGORIES.SUCCESS, payload: { items: Array<CategoryType> } }
	| { type: typeof actions.GET_CATEGORIES.FAIL, payload: { collection: string } };

export default function categoriesReducer(state: Categories = initialState, action: Action) {
	return produce<any>(state, (draft: Categories) => {
		switch (action.type) {
			case actions.GET_CATEGORIES.START:
				draft.isBusy = true;
				break;

			case actions.GET_CATEGORIES.SUCCESS:
				draft.data = action.payload.items;
				draft.isBusy = false;
				break;

			case actions.GET_CATEGORIES.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
