// @flow

import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from 'client/components/common/Responsive';

import ynetLogo from 'assets/client/ynet_logo.svg';
import mainLogo from 'assets/client/2024/site-logo.svg';
import centalLogo from 'assets/client/2024/central-logo.svg';

import Socialls from '../Socialls';
import AccessibilityIcon from '../AccessibilityIcon';
import * as css from './Header.scss';

type Props = {
	className?: string,
	enableShares?: boolean,
	isWhiteLogo?: boolean,
	onBackClick?: Function,
	isHideCenterText?: boolean,
	progressBar?: Function,
	isShowProgress?: boolean,
	isMain?: boolean,
};

const Header = ({
	className,
	enableShares,
	isWhiteLogo,
	onBackClick,
	isHideCenterText,
	progressBar,
	isShowProgress,
	isMain,
}: Props) => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const ynetLink = 'https://www.ynet.co.il';
	const glglzLink = '/';

	return (
		<header
			className={classNames(
				css.header,
				className,
				isShowProgress && css.headerBorder,
				isMain && css.isMain,
				!isHideCenterText && isMobile && css.mobileWithText
			)}
		>
			<div className={css.headerInner}>
				<div className={css.rightPart}>
					<a href={ynetLink} target="_blank" rel="noreferrer">
						<img src={ynetLogo} alt="ynet logo" />
					</a>
					<a href={glglzLink} rel="noreferrer">
						{isMain && !isMobile ? (
							<img src={mainLogo} alt="site logo" />
						) : (
							<img src={mainLogo} alt="site logo" />
						)}
					</a>
				</div>
				{!isHideCenterText && (
					<div className={css.centralPart}>
						<img src={centalLogo} alt="" />
					</div>
				)}

				<div className={css.leftPart}>
					<div className={css.sociallsMainWrapper}>
						<Socialls minified={isMobile} />
						<AccessibilityIcon iconColor="#000" />
					</div>
				</div>
			</div>

			{progressBar ? progressBar() : null}
		</header>
	);
};

Header.defaultProps = {
	className: '',
	enableShares: false,
	isWhiteLogo: false,
	onBackClick: () => {},
	isHideCenterText: false,
	progressBar: () => {},
	isShowProgress: false,
	isMain: false,
};

export { Header };
