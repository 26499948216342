/* eslint-disable react/no-unescaped-entities */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import bgImg from 'assets/client/2024/bg-tnx.jpg';
import bgImgMobile from 'assets/client/2024/thx-mobile.jpg';

import { Responsive } from 'client/components/common/Responsive';
import { Header, Footer } from 'client/components/common';

import * as css from './VotesEnded.scss';

type Props = {|
	...EnhancedProps,
	in: boolean,
|};

type State = {};

class VotesEnded extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { transitionState } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div
				className={classNames(css.thanks, css[transitionState])}
				style={isMobile ? null : { backgroundImage: `url(${bgImg})` }}
			>
				<Header isMain={isMobile} />
				<div className={css.content}>
					<div className={css.contentRight}>
						{isMobile && <img src={bgImgMobile} alt="thanks" />}
						<div className={css.text}>
							<h2>ההצבעה הסתיימה</h2>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withTransition(VotesEnded);
