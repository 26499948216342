/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import classNames from 'classnames';
// import yitLogo from 'assets/client/yit_logo.svg';
import * as css from './Footer.scss';

type Props = {
	className?: string,
	withCredentials?: boolean,
	isRelative?: boolean,
};

const Footer = ({ className, withCredentials, isRelative }: Props) => {
	const firstLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html';
	const secondLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html';
	// const thirdLink = 'https://yit.co.il/';

	return (
		<footer
			className={classNames(
				css.footer,
				className,
				withCredentials && css.withCredentials,
				isRelative && css.relative
			)}
		>
			<div className={css.footerWrapper}>
				{withCredentials && (
					<div className={css.credentialsWrapper}>
						<p>
							ההצבעה החל מיום 21.4.2024 בשעה 15:00 ועד יום 4.5.2024 בשעה 12:00. ניתן להצביע פעם אחת בלבד
							ולדרג 10 שירים מתוך 150 המועמדים לבחירה. הצבעת הקהל תהווה 50% מבחירת הזוכה ו-50% האחרים
							יקבעו בבחירת מערכת כאן גימל, צוות ynet ואנשי תרבות נוספים. תוצאות ההצבעה יתפרסמו ביום
							14.5.2024. הפעילות אינה נושאת פרסים.
						</p>
					</div>
				)}
				<div className={css.linksWrapper}>
					<a href={firstLink} target="_blank" rel="noreferrer">
						תנאי שימוש
					</a>
					<a href={secondLink} target="_blank" rel="noreferrer">
						מדיניות פרטיות
					</a>
					{/* <a href={thirdLink} target="_blank" rel="noreferrer">
						עיצוב, אפיון ופיתוח <img src={yitLogo} alt="yit-logo" />
					</a> */}
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
	withCredentials: false,
	isRelative: false,
};

export { Footer };
