import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	pageView: (url, label) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'virtualPageview',
			virtualPageURL: `${url}`,
			virtualPageTitle: `${label}`,
		}),
	clickOnShare: network =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'share_events',
			Category: 'Social',
			Action: 'Share From Site Head',
			Label: `${network}`,
		}),
	siteNavigationClick: navigationStage =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'navigation_events',
			Category: 'Navigation',
			Action: `${navigationStage}`,
		}),
	onVoted: (votingStage, selection) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'voting_events',
			Category: 'Vote',
			Action: `${votingStage}`,
			Label: `${selection}`,
		}),
	onPlaySoundClick: (artistName, songName) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'audio_events',
			Category: 'Audio',
			Action: 'Play',
			Label: `${artistName} - ${songName}`,
		}),
	goToQuizClick: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'navigation_events',
			Category: 'navigation',
			Action: 'Go To The Quizz',
			Label: ``,
		}),
};
