// @flow
import { connect } from 'react-redux';
// import _ from 'lodash';
import Thanks from './Thanks';

const mapState = (state: ClientStore) => ({});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Thanks);
