// @flow

import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';
import css from './Embed.scss';

type loadScriptProps = {
	attrs?: string,
	innerHTML?: any,
	targetNode: any,
};

class ScriptManager {
	scripts = new Map([]);

	addScript = (script: any) => {
		const key = script.attribs.src || script.children[0].data;
		this.scripts.set(key, script);
	};

	loadScript = ({ attrs, innerHTML, targetNode }: loadScriptProps) => {
		const script = document.createElement('script');

		_.forOwn(attrs, (value, key) => {
			script[key] = value;

			if (['async', 'defer'].includes(key)) {
				script[key] = ['', 'true'].includes(value);
			}
		});

		if (!attrs.src && innerHTML) {
			script.innerHTML = innerHTML;
		}

		if (targetNode) {
			targetNode.appendChild(script);
		}
	};

	clear() {
		this.scripts.clear();
	}
}

type Props = {
	code: string,
	className?: string,
};

export class Embed extends React.PureComponent<Props> {
	ScriptManager: ScriptManager = new ScriptManager();

	scriptsRef = React.createRef<HTMLDivElement>();

	static defaultProps = {
		className: '',
	};

	componentDidMount() {
		this.renderScripts();
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		const prevHTML = this.getHTML(prevProps.code);
		const currHTML = this.getHTML(this.props.code);

		if (prevHTML !== currHTML) {
			this.renderScripts();
		}
	}

	getHTML = (code: string) => {
		if (!code) {
			return '';
		}
		return decodeURI(code);
	};

	clearScriptHTML = () => {
		if (this.scriptsRef.current) {
			this.scriptsRef.current.innerHTML = '';
		}
	};

	renderScripts() {
		const { scripts, loadScript } = this.ScriptManager;

		// clear
		this.clearScriptHTML();

		// add scripts
		scripts.forEach(script => {
			const attrs = _.get(script, 'attribs');
			const innerHTML = _.get(script, `children.0.data`);

			loadScript({
				attrs,
				innerHTML,
				targetNode: this.scriptsRef.current,
			});
		});
	}

	renderHTML = (html: string) =>
		parse(html, {
			replace: domNode => {
				if (domNode.type === 'script') {
					this.ScriptManager.addScript(domNode);
					return <></>; // replace script with an empty fragment
				}

				return false;
			},
		});

	render() {
		const html = this.getHTML(this.props.code);
		const className = classnames(css.embed, this.props.className, { [css.empty]: !html });

		this.ScriptManager.clear();

		return (
			<div className={className}>
				<div className={css.scripts} ref={this.scriptsRef} />
				{html ? this.renderHTML(html) : 'Empty'}
			</div>
		);
	}
}
