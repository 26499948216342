// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
	secondaryColor?: string,
	onClick?: Function,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, secondaryColor, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 34} ${height || 34}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};

	switch (type) {
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g id="Group_338" transform="translate(-18 -24)">
						<rect
							id="Rectangle_299"
							width="34"
							height="34"
							rx="17"
							transform="translate(18 24)"
							fill="#ED1C24"
						/>
						<g id="XMLID_28_" transform="translate(-19.379 32)">
							<path
								id="XMLID_31_"
								d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
								fill="#fff"
								transform="translate(-124.881)"
							/>
							<path
								id="XMLID_30_"
								d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
								fill="#fff"
								transform="translate(0 -191.581)"
							/>
							<path
								id="XMLID_29_"
								d="M191.737 128.291l-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
								fill="#fff"
								transform="translate(-130.467 -113.162)"
							/>
						</g>
					</g>
				</svg>
			);
			break;
		case 'facebook_icon':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.867" viewBox="0 0 32 31.867">
					<path
						fill="#000"
						d="M16 .349a15.991 15.991 0 0 0-2.668 31.76V19.687h-3.86v-4.47h3.86v-3.3c0-3.824 2.336-5.908 5.748-5.908a31.853 31.853 0 0 1 3.446.175v4h-2.367c-1.855 0-2.213.881-2.213 2.175v2.853h4.427l-.577 4.47h-3.85v12.534A15.992 15.992 0 0 0 16 .349z"
						transform="translate(0 -0.349)"
					/>
				</svg>
			);
			break;
		case 'whatsapp_icon':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="32.002" height="32.002" viewBox="0 0 32.002 32.002">
					<path
						fill="#000"
						d="M14249 6987a16 16 0 1 1 16 16 16 16 0 0 1-16-16zm6.763-.266a9.56 9.56 0 0 0 1.28 4.789l-1.358 4.96 5.074-1.333a9.6 9.6 0 0 0 4.577 1.168 9.577 9.577 0 1 0-9.577-9.584zm9.573 7.964a7.949 7.949 0 0 1-4.05-1.107l-.29-.172-3.015.789.807-2.936-.19-.3a7.96 7.96 0 1 1 6.742 3.728zm-4.024-12.082a2.674 2.674 0 0 0-.835 1.993 4.678 4.678 0 0 0 .975 2.477 10.7 10.7 0 0 0 4.089 3.609 13.637 13.637 0 0 0 1.362.505 3.233 3.233 0 0 0 1.509.093 2.452 2.452 0 0 0 1.613-1.136 1.981 1.981 0 0 0 .14-1.136c-.062-.1-.219-.161-.459-.279s-1.416-.7-1.635-.778-.38-.122-.537.118-.62.778-.756.939-.28.179-.521.058a6.489 6.489 0 0 1-1.924-1.187 7.162 7.162 0 0 1-1.334-1.656c-.136-.24 0-.358.107-.487a6.871 6.871 0 0 0 .6-.821.439.439 0 0 0-.021-.419c-.057-.118-.537-1.294-.738-1.774-.172-.412-.348-.412-.487-.412h-.05c-.14-.007-.3-.007-.459-.007a.88.88 0 0 0-.64.3z"
						transform="translate(-14248.999 -6971)"
					/>
				</svg>
			);
			break;
		case 'back':
			icon = (
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="Icon feather-arrow-left-circle">
						<path
							id="Path 1265"
							d="M1.53848 14.923C1.53848 17.5398 2.31445 20.0978 3.76827 22.2736C5.22208 24.4494 7.28844 26.1452 9.70605 27.1466C12.1236 28.148 14.7839 28.4101 17.3504 27.8996C19.9169 27.389 22.2744 26.1289 24.1248 24.2786C25.9751 22.4282 27.2353 20.0707 27.7458 17.5042C28.2563 14.9377 27.9943 12.2774 26.9929 9.85983C25.9915 7.44222 24.2956 5.37586 22.1199 3.92205C19.9441 2.46823 17.386 1.69226 14.7692 1.69226C13.0317 1.69226 11.3113 2.03448 9.70605 2.69939C8.10082 3.3643 6.64227 4.33887 5.41368 5.56746C4.18509 6.79605 3.21052 8.2546 2.54561 9.85983C1.8807 11.4651 1.53848 13.1855 1.53848 14.923Z"
							stroke="#2056FE"
							strokeWidth="2.46154"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Path 1266"
							d="M14.7692 9.63074L20.0617 14.9232L14.7692 20.2156"
							stroke="#2056FE"
							strokeWidth="2.46154"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Path 1267"
							d="M9.47682 14.9231H20.0617"
							stroke="#2056FE"
							strokeWidth="2.46154"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			);
			break;
		case 'share':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
					<g transform="translate(-18 -24)">
						<rect width="60" height="60" fill={color} rx="30" transform="translate(18 24)" />
						<g>
							<g>
								<g>
									<g>
										<path
											fill="#fff"
											d="M9.8 11.591a5.032 5.032 0 0 1 0 2.818l9.246 4.9a3.986 3.986 0 1 1-.937 1.767l-9.247-4.9a5 5 0 1 1 0-6.352l9.247-4.9a4 4 0 1 1 .936 1.776z"
											transform="translate(33 40.998) translate(-419 -227) translate(419 227)"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case 'slider-button-0':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="58" viewBox="0 0 32 58">
					<g id="Group_1472" transform="translate(1238.266 657.291) rotate(180)">
						<path
							id="Subtraction_1"
							fill="#1d315d"
							d="M2.909 58C1.94 58 .961 57.952 0 57.857V.143C.963.048 1.942 0 2.909 0A29.045 29.045 0 0 1 23.48 8.494a28.9 28.9 0 0 1 6.234 9.218 29.013 29.013 0 0 1 0 22.576 28.9 28.9 0 0 1-6.234 9.218A29.045 29.045 0 0 1 2.909 58z"
							transform="translate(1238.266 657.291) rotate(180)"
						/>
						<g id="Group_1431" transform="translate(1230.795 634.472) rotate(180)">
							<path
								id="Path_327"
								d="M6.283 0L0 6.283l3.273 3.273 3.011 3.01"
								className="cls-2"
								transform="translate(7.379)"
								fill="none"
								stroke="#fff"
								strokeLinecap="square"
								strokeWidth="3px"
							/>
							<path
								id="Path_328"
								d="M6.283 0L0 6.283l3.273 3.273 3.011 3.01"
								className="cls-2"
								transform="translate(0)"
								fill="none"
								stroke="#fff"
								strokeLinecap="square"
								strokeWidth="3px"
							/>
						</g>
					</g>
				</svg>
			);
			break;

		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 34,
	height: 34,
	color: '#111',
	secondaryColor: 'none',
	onClick: () => {},
};

export { Icon };
