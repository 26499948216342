// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateVotes } from 'client/actions';
import Results from './Results';

const mapState = (state: ClientStore) => {
	return {
		categories: _.orderBy(_.get(state.categories, 'data', []), 'orderData'),
		vote: state.vote,
		allItems: _.get(state.items, 'data', []),
	};
};

const mapDispatch = {
	submitVotes: updateVotes,
};

export default connect(mapState, mapDispatch)(Results);
