// @flow

import * as React from 'react';
import classNames from 'classnames';
import firebase from 'firebase/app';

import * as css from './Popup.scss';

type Props = {
	className?: string,
	onClickSendButton: Function,
	onClose: Function,
};

type State = {
	recaptchaSolved: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };
export default class Popup extends React.Component<Props, State> {
	popupRef: ReactObjRef;
	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			recaptchaSolved: false,
		};
		this.popupRef = React.createRef();
	}

	componentDidMount() {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(`recaptcha-container`, {
			size: 'normal',
			callback: response => {
				this.setState({ recaptchaSolved: true });
				console.info('reCAPTCHA solved');
			},
		});

		window.recaptchaVerifier.render();

		document.addEventListener('click', this.documentClickListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClickListener);
	}

	documentClickListener = (e: MouseEvent) => {
		if (this.popupRef.current) {
			if (this.popupRef.current.contains((e.target: any))) return false;
		}

		this.props.onClose();
	};

	render() {
		const { className, onClickSendButton } = this.props;

		return (
			<div className={classNames(css.popup, className)}>
				<div className={css.inner} ref={this.popupRef}>
					<div id="recaptcha-container" className={css.captcha} />
					<button
						className={classNames(css.submitButton, !this.state.recaptchaSolved && css.disable)}
						id="submit-button"
						type="button"
						onClick={onClickSendButton}
					>
						סיימתי להצביע
					</button>
				</div>
			</div>
		);
	}
}
