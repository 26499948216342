// @flow
/* eslint-disable indent, react/no-danger */
import _ from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import { Responsive } from 'client/components/common/Responsive';

import Swiper from 'swiper';

import * as css from './ProgressBar.scss';

type Props = {
	swiper: null | Swiper,
	categories: any[],
	getActiveCategory: Function,
	activeCategoryId: string,
	vote: string[][],
	// scrollToButton: Function,
};

const mapState = (state: ClientStore) => ({
	categories: _.orderBy(_.get(state.categories, 'data', []), 'orderData'),
	vote: state.vote,
});
const mapDispatch = {};

function ProgressBar({ swiper, categories, vote, getActiveCategory, activeCategoryId /* , scrollToButton */ }: Props) {
	const [activeTab, setActiveTab] = useState(0);
	const [completeTabs, setCompleteTabs] = useState<number[]>([]);
	const [isNextAvailable, setIsNextAvailable] = useState<number>(0);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	// const mobileTitle = '<span>המצעד</span> הישראלי השנתי ';

	useEffect(() => {
		categories.forEach((category, index) => {
			if (category.id === activeCategoryId) {
				setActiveTab(index);
			}
		});
	}, [activeCategoryId]);

	useEffect(() => {
		if (swiper) {
			if (Number(categories[swiper.activeIndex].maxItems) === vote[swiper.activeIndex].length) {
				// if the number of votes on this page is equal to maxItems
				const temp = [...completeTabs];
				temp.push(activeTab);
				setCompleteTabs(temp);

				// if (swiper.activeIndex === 4) {
				// 	scrollToButton();
				// 	return;
				// }

				if (typeof categories[activeTab + 1] === 'undefined') {
					// if this is the last page
					// do nothing
				} else {
					// go to next page
					swiper.slideTo(activeTab + 1);
					setActiveTab(activeTab + 1);
					getActiveCategory(categories[swiper.activeIndex].id);
				}
			} else {
				const temp = [...completeTabs];
				temp.filter(item => item !== activeTab);
				setCompleteTabs(temp);
				setIsNextAvailable(activeTab + 1);
			}
		}
	}, [vote]);

	if (!categories) {
		return;
	}
	return (
		<>
			{/* <Responsive query={[Responsive.MOBILE, Responsive.TABLET_P]}>
				<p className={css.mobileTitle} dangerouslySetInnerHTML={{ __html: mobileTitle }} />
			</Responsive> */}
			<div className={`${css.progressBar} `}>
				{categories.map((category, index) => (
					<div
						className={`${css.tab} `}
						key={category.id}
						data-id={category.id}
						onClick={e => {
							const { id } = e.currentTarget.dataset;
							let resolved = false;
							const activeCat = categories.find(currCat => currCat.id === id);

							const activeCatIndex = activeCat && categories.findIndex(currCat => currCat.id === id);

							const prevCategories = categories.slice(0, activeCatIndex);

							prevCategories.forEach((cat, idx) => {
								if (vote[idx].length < Number(cat.minItems)) {
									resolved = true;
								}
							});

							if (!(index !== 0 && resolved)) {
								setActiveTab(index);
								if (swiper) {
									swiper.slideTo(index);
								}
								getActiveCategory(category.id);
							}
						}}
						style={{
							// eslint-disable-next-line
							// flex: activeTab === index ? (isMobile ? 2 : 3) : 1,
							flex: !isMobile && activeTab === index ? 3 : '1 1 1',
						}}
					>
						<p
							className={classNames(activeTab === index && css.active)}
							style={{ textAlign: !isMobile && activeTab === index ? 'right' : 'center' }}
						>
							{category.name}
						</p>
						{activeTab === index && <span>{`${vote[activeTab].length}/${Number(category.maxItems)}`}</span>}
						<div
							className={classNames(
								css.bar,
								activeTab === index && css.active,
								// vote[index].length >= Number(categories[index].minItems) &&
								// 	vote[index + 1].length === 0 &&
								// 	css.isNextAvailable,
								index !== 0 &&
									vote[index - 1].length < Number(categories[index - 1].minItems) &&
									css.disabled,
								// if previous tab vote's are less than minItems, disable
								vote[index].length >= Number(categories[index].minItems) && css.completed
							)}
						>
							{[...Array(Number(category.maxItems)).keys()].map((item, itemIndex) => (
								<div
									key={item}
									className={`${css.progress} ${
										activeTab === index || completeTabs.includes(index) ? css.active : ''
									}
								`}
									style={{
										backgroundColor: vote[index].length > itemIndex ? '#2056fd' : 'transparent',
										borderRight:
											/* eslint-disable */
											activeTab !== index || itemIndex === 0
												? 'none'
												: isMobile
												? 'solid white 2px'
												: 'solid white 1px',
									}}
								></div>
							))}
						</div>
					</div>
				))}
			</div>
			<Responsive query={[Responsive.MOBILE, Responsive.TABLET_P]}>
				<p className={`${css.description} `}>{categories[activeTab].description}</p>
			</Responsive>
		</>
	);
}

export default connect(mapState, mapDispatch)(ProgressBar);
