/* eslint-disable no-case-declarations */
// @flow
// import _ from 'lodash';
import produce from 'immer';

import * as actions from 'client/constants/actions';

type Votes = string[];
type VoteState = Votes[];

const initialState: VoteState = [[], [], [], [], [], [], [], [], []];

type Action =
	| { type: typeof actions.ADD_VOTE, payload: { id: string, page: number } }
	| { type: typeof actions.REMOVE_VOTE, payload: { id: string, page: number } };

export default function itemsReducer(state: VoteState = initialState, action: Action) {
	return produce<any>(state, (draft: VoteState) => {
		const { payload } = action;
		switch (action.type) {
			case actions.ADD_VOTE:
				draft[payload.page].push(payload.id);
				break;

			case actions.REMOVE_VOTE:
				const index = state[payload.page].indexOf(payload.id);
				draft[payload.page].splice(index, 1);
				break;

			default:
				break;
		}
	});
}
