/* eslint-disable max-len */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { detectIE } from 'common/utils/detect-ie';
import { gsap } from 'gsap';
import faviconImg from 'assets/favicon.png';
// import { isMobileOrTablet } from 'client/components/common/Responsive';
import { CAN_USE_DOM, IS_DEVEL } from 'common/constants';
import Intro from 'client/components/pages/Intro';
import Thanks from 'client/components/pages/Thanks';
import Vote from 'client/components/pages/Vote';
import VotesEnded from 'client/components/pages/VotesEnded';
import Results from 'client/components/pages/Results';
// import Password from './common/Password';
import { ROOT_ROUTE, VOTE_ROUTE, THANKS_ROUTE, VOTES_ENDED_ROUTE, RESULTS_ROUTE } from '../constants/routes';

import * as css from './App.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
|};

// type State = {
// 	password: boolean,
// };

type ReactObjRef = { current: HTMLDivElement | null };

class App extends React.PureComponent<Props> {
	contentRef: ReactObjRef;
	// state = {
	// 	password: false,
	// };

	static defaultProps = {
		isBusy: true,
	};

	constructor(props: Props) {
		super(props);

		this.contentRef = React.createRef();
	}

	async componentDidMount() {
		const isIE = detectIE();

		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');

			// const pass = window.localStorage.getItem('gz1') === 'glglz007';
			// this.setState({ password: pass });
		}

		if (CAN_USE_DOM && !IS_DEVEL) {
			// const notDesktop = isMobileOrTablet();
			// const hostname = notDesktop ? 'm.ynet.co.il' : 'ynet.co.il';
			// window.dataLayer = window.dataLayer || [];
			// window.dataLayer.push({
			// hostname,
			// Dcpath: '102fm-hit-parade-2022',
			// });
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		const title = 'הצביעו והשפיעו: מצעד אגן הים התיכון';
		const description =
			'ynet מארח את מצעד אגן הים התיכון של כאן גימל ואתם מוזמנים לבחור את השיר הים תיכוני האהוב של כל הזמנים';
		// const seoTitle = 'המצעד הישראלי השנתי - ynet | גלגלצ';
		// const seoDescription =
		// 	'הצביעו לשירים ולאמנים שעשו לכם את השנה. התוצאות ייחשפו במשדר מיוחד ב-ynet ובגלגלצ - ביום חמישי, 14 בספטמבר, בשעות 18:00-14:00';

		const image = '/assets/client/share.jpg';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
					{ property: 'og:url', content: 'https://kangimel.ynet.co.il' },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1000);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	resetScroll = () => window.scrollTo(0, 0);

	scrollTopContent = () => {
		this.resetScroll();
		if (this.contentRef && this.contentRef.current) {
			this.contentRef.current.scrollTo(0, 0);
		}
	};

	scrollToButton = () => {
		const scrolledEl = document.getElementById('submit-button');
		const wrapper = this.contentRef.current;
		const tweener = { value: wrapper?.scrollTop || 0 };

		if (scrolledEl) {
			gsap.to(tweener, {
				duration: 0.5,
				value: scrolledEl.offsetTop,
				onUpdate: () => {
					if (wrapper) {
						wrapper.scrollTo({ top: tweener.value, behavior: 'auto' });
					}
				},
			});
		}
	};

	// onPasswordChange = (pass: string) => {
	// 	this.setState({ password: pass === 'glglz007' });
	// };

	render() {
		// const isShowPasswordInput = !this.state.password;

		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				{/* {this.state.password && ( */}
				<div className={css.content} ref={this.contentRef}>
					<Switch>
						<Route exact path={ROOT_ROUTE}>
							{({ match, ...props }) => (
								<Intro location={props.location} history={props.history} in={!!match} />
							)}
						</Route>
						<Route exact path={VOTE_ROUTE}>
							{({ match, ...props }) => (
								<Vote
									location={props.location}
									history={props.history}
									in={!!match}
									scrollTopContent={this.scrollTopContent}
									scrollToButton={this.scrollToButton}
								/>
							)}
						</Route>
						<Route exact path={RESULTS_ROUTE}>
							{({ match, ...props }) => <Results history={props.history} in={!!match} />}
						</Route>
						<Route exact path={THANKS_ROUTE}>
							{({ match, ...props }) => <Thanks location={props.location} in={!!match} />}
						</Route>
						<Route exact path={VOTES_ENDED_ROUTE}>
							{({ match, ...props }) => <VotesEnded location={props.location} in={!!match} />}
						</Route>
					</Switch>
				</div>
				{/* )} */}
				{/* <Password isActive={isShowPasswordInput} onChange={this.onPasswordChange} /> */}
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
